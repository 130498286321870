import { OnlineExchange } from '@framework/types/home';
import Image from 'next/image';
import React, { FC, useEffect, useState } from "react";
import ValueQuotation, { ValueQuotationV2 } from "../ValueQuotation";
import { set } from 'date-fns';
import ButtonCed from '../ButtonCed';

export interface ExchangeHousesProps {
  onlineExchangeHouses: OnlineExchange[];
  page: string | null;
  valueCal: number;
  currency: string;
  section?: string;
  dataSocket?: any;
}

const ExchangeHouses: FC<ExchangeHousesProps> = ({ onlineExchangeHouses, page, valueCal, currency, section, dataSocket }) => {
  const [showMoreAll, setShowMoreAll] = useState(false);
  const [showMoreId, setShowMoreId] = useState('');  
  const [firstPart, setFirstPart] = useState<OnlineExchange[] | null>(null);
  const [secondPart, setSecondPart] = useState<OnlineExchange[] | null>(null);

  useEffect(() => {
    if(onlineExchangeHouses){
      renderData(onlineExchangeHouses);
    }

    if (dataSocket) {
      let onlineChange = onlineExchangeHouses.map((item) => {
        if(item.id === dataSocket.directorio_id){
          item.rates.buy.cost = dataSocket.rates.buy.cost;
          item.rates.sale.cost = dataSocket.rates.sale.cost;
        }
        return item;
      }
      );
      onlineExchangeHouses = onlineChange;
      renderData(onlineExchangeHouses);
    }
  }
  , [dataSocket, onlineExchangeHouses]);
  
  function name(array: OnlineExchange[] | null, type: string) {
    if(type == 'par'){
      const data = array?.filter((element,index) => index % 2 == 0)
      return data
    }else{
      const data = array?.filter((element,index) => index % 2 == 1)
      return data
    }
  }

  function renderData(array: any) {
    order(array);
    const firstPart = name(order(array), "par");
    const secondPart = name(order(array), "impar");
    if(firstPart && secondPart){
      setFirstPart(firstPart);
      setSecondPart(secondPart);
    }
  }

  function order(array: any) {
    const data = array.sort((a: any, b: any) => {
      if (!a.order) return 1;
      if (!b.order) return -1;
      return Number(a.order) - Number(b.order);
    });
    return data;
  }

  const hideInfo = () => {
    setShowMoreId("");
    setShowMoreAll(false);
  };

  return (
    <>
      <div className={`${page == "cambioOnline" ? "overflow-hidden max-w-5xl" : "overflow-hidden max-w-5xl md:flex"}`}>
        <div className={`${page == "cambioOnline" ? "w-full " : "md:w-1/2 md:mr-[10px]"}`}>
          <div className={`${page == "cambioOnline" ? "justify-end" : "justify-between"} flex mb-4`}>
            {showMoreAll ? (
              <div className={`${page == "cambioOnline" ? "hidden" : ""}  flex`} onClick={() => hideInfo()}>
                <div>
                  <h3 className="text-secondary text-lg leading-5 w-24 md:w-[132px] ">Ocultar información</h3>
                </div>
                <button className="mx-[18px] md:mx-6" aria-label='buttonArrow'>
                  <span className="icon icon-arrow" />
                </button>
              </div>
            ) : (
              <div className={`${page == "cambioOnline" ? "hidden" : ""} flex`} onClick={() => setShowMoreAll(true)}>
                <h3 className="text-[#0096A6] text-lg leading-5 w-24 md:w-[132px] hover:text-secondary">
                  Mostrar información
                </h3>
                <button className="mx-[18px] md:mx-8" aria-label='buttonArrow2'>
                  <span className="icon icon-arrow rotate-180 " />
                </button>
              </div>
            )}
            <div className="flex md:justify-end">
              <p
                className={`${
                  currency == "PEN"
                    ? "hidden"
                    : currency == ""
                    ? "text-lg text-black text-center my-auto mx-1 md:w-20 md:mx-0"
                    : "flex justify-center text-lg text-black w-40"
                } ${valueCal ? 'mr-3 md:mr-0' : ''} `}
              >
                Compra
              </p>
              <p
                className={`${
                  currency == "USD"
                    ? "hidden"
                    : currency == ""
                    ? "text-lg text-black text-center my-auto mx-2 md:w-20 md:mx-0"
                    : "flex justify-center text-lg text-black w-40"
                } ${valueCal ? 'mr-3 md:mr-0' : ''} `}
              >
                Venta
              </p>
            </div>
          </div>
          {onlineExchangeHouses?.map(({ bank, id, img, schedule, rates, color, site, width, height, order, change_max, change_min, cupon, tasas_referenciales }, index) => (
            <div key={index} className={`${page == "cambioOnline" ? "" : "md:hidden"}`}>
              <div
                className={
                  Number(order) % 2 > 0 || id === showMoreId
                    ? " bg-gray1 flex align-middle py-2 justify-between"
                    : ` flex align-middle py-2 justify-between`
                }
              >
                {img && (
                  <div className="w-[90px] md:w-36 h-auto flex align-middle justify-center ml-2">
                    <Image src={`${img}`} width={parseInt(width)} height={parseInt(height)} layout="fixed" alt="img" />
                  </div>
                )}
                {showMoreAll || id === showMoreId ? (
                  <div className={`${page == "cambioOnline" ? "hidden " : "my-auto"}`} onClick={() => hideInfo()}>
                    <button className="md:mx-4" aria-label={'boton_' + index}>
                      <span className="icon icon-arrow" />
                    </button>
                  </div>
                ) : (
                  <div
                    className={`${page == "cambioOnline" ? "hidden " : "my-auto"}`}
                    onClick={() => setShowMoreId(id)}
                  >
                    <button className="mx-2 md:mx-4" aria-label={'boton2_' + index}>
                      <span className="icon icon-arrow rotate-180" />
                    </button>
                  </div>
                )}
                <div className={`${page == "cambioOnline" ? "hidden" : "flex"} my-auto`}>
                  <a href={site} target="_blank" rel="noreferrer nofollow">
                  <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                  </a>
                </div>
                <div className={`${page == "cambioOnline" ? "hidden w-1/2 md:flex" : "hidden"}`}>
                  <div className="w-2/4 justify-start text-xs text-black my-auto mx-4">
                    <div className="mt-4">
                      <p>{bank}</p>
                        {change_min
                          ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p>
                          : ''
                        }
                        {change_max
                          ? <p className="text-xs text-black">Cambio máximo ${change_max} </p>
                          : ""
                        }
                        {cupon
                          ? <p className="text-xs text-black">Cupón: {cupon} </p>
                          : ""
                        }
                        {tasas_referenciales
                          ? <p className="text-xs text-black">Tasas referenciales a partir de: {tasas_referenciales} </p>
                          : ""
                        }
                    </div>
                  </div>
                  <div className="w-2/4 flex md:justify-center text-xs md:text-black ">
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end mr-4">L - V</p>
                        <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end ">S</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end">D</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${page == "cambioOnline" ? "flex my-auto" : "hidden"} '`}>
                  <a href={site} target="_blank" rel="noreferrer nofollow">
                    <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                  </a>
                </div>
                <div className="flex items-center justify-between text-xl ">
                  <ValueQuotationV2
                    exchangeValue={rates}
                    title={"home"}
                    valueCal={valueCal}
                    currency={currency}
                    type={"home"}
                    section={section}
                  />
                </div>
              </div>
              <div className={showMoreAll || id === showMoreId || page == "cambioOnline" ? "content show" : " content"}>
                <div
                  className={`${
                    page == "cambioOnline" ? "flex bg-Gray px-2 text-sm md:hidden" : "bg-Gray flex px-2 mb-2 text-sm"
                  }`}
                >
                  <div className="w-1/2 border-t-[1px] border-[#DADADA]  flex justify-start text-xs text-black md:w-full ">
                    <div className="w-4/5 mt-4">
                      <p>{bank}</p>
                        {change_min
                          ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p>
                          : ''
                        }
                        {change_max
                          ? <p className="text-xs text-black">Cambio máximo ${change_max} </p>
                          : ""
                        }
                        {cupon
                          ? <p className="text-xs text-black">Cupón: {cupon} </p>
                          : ""
                        }
                        {tasas_referenciales
                          ? <p className="text-xs text-black">Tasas referenciales a partir de: {tasas_referenciales} </p>
                          : ""
                        }
                    </div>
                  </div>
                  <div className="w-1/2 border-t-[1px] border-[#DADADA]  flex justify-center text-xs text-black md:w-full">
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                        <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end mb-1">S</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end">D</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {firstPart?.map(({ bank, id, img, schedule, rates, color, site, width, height, change_max, change_min, cupon, tasas_referenciales}, index) => (
            <div key={index} className={`${page == "cambioOnline" ? "hidden" : "hidden md:block"}`}>
              <div
                className={
                  Number(index) % 2 > 0 || id === showMoreId
                    ? " bg-gray1 flex align-middle py-2 justify-between"
                    : `flex align-middle py-2 justify-between`
                }
              >
                {img && (
                  <div className="exchange_img w-[80px] md:w-36 h-auto flex align-middle justify-center">
                    <Image src={`${img}`} width={parseInt(width)} height={parseInt(height)} layout="fixed" alt="img" />
                  </div>
                  )}
                {showMoreAll || id === showMoreId ? (
                  <div className={`${page == "cambioOnline" ? "hidden " : "my-auto"}`} onClick={() => hideInfo()}>
                    <button className="mx-0 md:mx-1" aria-label={'boton3_' + index}>
                      <span className="icon icon-arrow" />
                    </button>
                  </div>
                ) : (
                  <div
                    className={`${page == "cambioOnline" ? "hidden " : "my-auto"}`}
                    onClick={() => setShowMoreId(id)}
                  >
                    <button className="mx-0 md:mx-1" aria-label={'boton4_' + index}>
                      <span className="icon icon-arrow rotate-180" />
                    </button>
                  </div>
                )}
                <div className={`${page == "cambioOnline" ? "hidden" : "flex"} my-auto`}>
                  <a href={site} target="_blank" rel="noreferrer nofollow">
                  <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                  </a>
                </div>
                <div className={`${page == "cambioOnline" ? "hidden w-1/2 md:flex" : "hidden"}`}>
                  <div className="w-2/4 justify-start text-xs text-black my-auto mx-4">
                    <div className="mt-4">
                      <p>{bank}</p>
                        {change_min
                          ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p>
                          : ''
                        }
                        {change_max
                          ? <p className="text-xs text-black">Cambio máximo ${change_max} </p>
                          : ""
                        }
                        {cupon
                          ? <p className="text-xs text-black">Cupón: {cupon} </p>
                          : ""
                        }
                        {tasas_referenciales
                          ? <p className="text-xs text-black">Tasas referenciales a partir de: {tasas_referenciales} </p>
                          : ""
                        }
                    </div>
                  </div>
                  <div className="w-2/4 flex md:justify-center text-xs md:text-black ">
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end mr-4">L - V</p>
                        <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end ">S</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end">D</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${page == "cambioOnline" ? "flex my-auto" : "hidden"} '`}>
                  <a href={site} target="_blank" rel="noreferrer nofollow">
                  <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                  </a>
                </div>
                <div className="flex items-center justify-center text-xl w-40">
                  <ValueQuotationV2
                    exchangeValue={rates}
                    title={"home"}
                    valueCal={valueCal}
                    currency={currency}
                    type={"home"}
                    section={section}
                  />
                </div>
              </div>
              <div className={showMoreAll || id === showMoreId || page == "cambioOnline" ? "content show" : " content"}>
                <div
                  className={`${
                    page == "cambioOnline" ? "flex bg-Gray px-2 text-sm md:hidden" : "bg-Gray flex px-2 mb-2 text-sm"
                  }`}
                >
                  <div className="w-1/2 border-t-[1px] border-[#DADADA]  flex justify-start text-xs text-black md:w-full ">
                    <div className="w-4/5 mt-4">
                      <p>{bank}</p>
                        {change_min
                          ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p>
                          : ''
                        }
                        {change_max
                          ? <p className="text-xs text-black">Cambio máximo ${change_max} </p>
                          : ""
                        }
                        {cupon
                          ? <p className="text-xs text-black">Cupón: {cupon} </p>
                          : ""
                        }
                        {tasas_referenciales
                          ? <p className="text-xs text-black">Tasas referenciales a partir de: {tasas_referenciales} </p>
                          : ""
                        }
                    </div>
                  </div>
                  <div className="w-1/2 border-t-[1px] border-[#DADADA]  flex justify-center text-xs text-black md:w-full">
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                        <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end mb-1">S</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end">D</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={`md:pt-3 ${page == "cambioOnline" ? "md:w-full" : "md:w-1/2 md:ml-[5px]"}`}>
          <div className={`${page == "cambioOnline" ? "hidden" : "hidden md:flex md:w-full md:mb-4 md:justify-end"}`}>
            <p
              className={`${
                currency == "PEN"
                  ? "hidden"
                  : currency == ""
                  ? "text-lg text-black text-center my-auto w-20"
                  : "flex justify-center text-lg text-black w-40"
              } `}
            >
              Compra
            </p>
            <p
              className={`${
                currency == "USD"
                  ? "hidden"
                  : currency == ""
                  ? "text-lg text-black text-center my-auto w-20"
                  : "flex justify-center text-lg text-black w-40"
              }`}
            >
              Venta
            </p>
          </div>
          {secondPart?.map(({ bank, id, img, schedule, rates, color, site, width, height, change_max, change_min, cupon, tasas_referenciales }, index) => (
            <div key={index} className={`${page == "cambioOnline" ? "hidden" : "hidden md:block"}`}>
              <div
                className={
                  Number(index) % 2 > 0 || id === showMoreId
                    ? "bg-gray1 flex align-middle py-2 justify-between"
                    : `flex align-middle py-2 justify-between`
                }
              >
                {img && (
                  <div className="exchange_img w-[90px] md:w-36 h-auto flex align-middle justify-center">
                    <Image src={`${img}`} width={parseInt(width)} height={parseInt(height)} layout="fixed" alt="img" />
                  </div>
                  )}
                {showMoreAll || id === showMoreId ? (
                  <div className={`${page == "cambioOnline" ? "hidden " : "my-auto"}`} onClick={() => hideInfo()}>
                    <button className="mx-0 md:mx-1" aria-label={'boton_1_' + index}>
                      <span className="icon icon-arrow" />
                    </button>
                  </div>
                ) : (
                  <div
                    className={`${page == "cambioOnline" ? "hidden " : "my-auto"}`}
                    onClick={() => setShowMoreId(id)}
                  >
                    <button className="mx-0 md:mx-1" aria-label={'boton_2_' + index}>
                      <span className="icon icon-arrow rotate-180" />
                    </button>
                  </div>
                )}
                <div className={`${page == "cambioOnline" ? "hidden" : "flex"} my-auto`}>
                  <a href={site} target="_blank" rel="noreferrer nofollow">
                  <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                  </a>
                </div>
                <div className={`${page == "cambioOnline" ? "hidden w-1/2 md:flex" : "hidden"}`}>
                  <div className="w-2/4 justify-start text-xs text-black my-auto mx-4">
                    <div className="mt-4">
                      <p>{bank}</p>
                        {change_min
                          ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p>
                          : ''
                        }
                        {change_max
                          ? <p className="text-xs text-black">Cambio máximo ${change_max} </p>
                          : ""
                        }
                        {cupon
                          ? <p className="text-xs text-black">Cupón: {cupon} </p>
                          : ""
                        }
                        {tasas_referenciales
                          ? <p className="text-xs text-black">Tasas referenciales a partir de: {tasas_referenciales} </p>
                          : ""
                        }
                    </div>
                  </div>
                  <div className="w-2/4 flex md:justify-center text-xs md:text-black ">
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end mr-4">L - V</p>
                        <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end ">S</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end">D</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${page == "cambioOnline" ? "flex my-auto" : "hidden"} '`}>
                  <a href={site} target="_blank" rel="noreferrer nofollow">
                  <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                  </a>
                </div>
                <div className="flex items-center justify-center text-xl w-40">
                  <ValueQuotationV2
                    exchangeValue={rates}
                    title={"home"}
                    valueCal={valueCal}
                    currency={currency}
                    type={"home"}
                    section={section}
                  />
                </div>
              </div>
              <div className={showMoreAll || id === showMoreId || page == "cambioOnline" ? "content show" : " content"}>
                <div
                  className={`${
                    page == "cambioOnline" ? "flex bg-Gray px-2 text-sm md:hidden" : "bg-Gray flex px-2 mb-2 text-sm"
                  }`}
                >
                  <div className="w-1/2 border-t-[1px] border-[#DADADA]  flex justify-start text-xs text-black md:w-full ">
                    <div className="w-4/5 mt-4">
                      <p>{bank}</p>
                      {change_min
                          ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p>
                          : ''
                        }
                        {change_max
                          ? <p className="text-xs text-black">Cambio máximo ${change_max} </p>
                          : ""
                        }
                        {cupon
                          ? <p className="text-xs text-black">Cupón: {cupon} </p>
                          : ""
                        }
                        {tasas_referenciales
                          ? <p className="text-xs text-black">Tasas referenciales a partir de: {tasas_referenciales} </p>
                          : ""
                        }
                    </div>
                  </div>
                  <div className="w-1/2 border-t-[1px] border-[#DADADA]  flex justify-center text-xs text-black md:w-full">
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                        <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end mb-1">S</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="w-9 flex justify-end">D</p>
                        <p className="w-24 flex justify-start">
                          {schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ExchangeHouses;
