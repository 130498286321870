import { OnlineExchange } from '@framework/types/home';
import Image from 'next/image';
import React, { FC, useEffect, useState, useRef } from "react";
import ValueQuotation, { ValueQuotationV2 } from "../ValueQuotation";
import Arrow from '../icons/Arrow';
import ArrowCustom from '../icons/ArrowCustom';
import ButtonCed from '../ButtonCed';

export interface ExchangeHousesProps {
  onlineExchangeHouses: OnlineExchange[];
  page: string | null;
  valueCal: number;
  currency: string;
  section?: string;
  dataSocket?: any;
  messageData?: any;
}

const ExchangeHousesV3: FC<ExchangeHousesProps> = ({ onlineExchangeHouses, page, valueCal, currency, section, dataSocket }) => {
  const [showMoreAll, setShowMoreAll] = useState(false);
  const [showMoreId, setShowMoreId] = useState('');  
  const [firstPart, setFirstPart] = useState<OnlineExchange[] | null>(null);
  const [secondPart, setSecondPart] = useState<OnlineExchange[] | null>(null);
  const [updatedExchangeHouses, setUpdatedExchangeHouses] = useState<OnlineExchange[]>([]);
  const [timeUpdate, setTimeUpdate] = useState<number>(0);


  useEffect(() => {
    if (onlineExchangeHouses) {
      const updatedHouses = onlineExchangeHouses.map((house) => {
          const convertedTime = house.timestamp ? convertTime(house.timestamp) : undefined;

          if (convertedTime) {
              return { ...house, convertedTime };
          }
          return house;
      });
      setUpdatedExchangeHouses([...updatedHouses]);
      renderData(updatedHouses);
    }
  }, [onlineExchangeHouses]);

  useEffect(() => {
    if (dataSocket) {
      let onlineChange = updatedExchangeHouses.map((item) => {
        if (item.id === dataSocket.directorio_id) {
          let convertedTime = convertTime(dataSocket.timestamp);
          item = { ...item, rates: { ...item.rates, ...dataSocket.rates }, convertedTime};
        }
        return item;
      });
      setUpdatedExchangeHouses(onlineChange);
      renderData(onlineChange);
    }
  }, [dataSocket]);

  function name(array: OnlineExchange[] | null, type: string) {
    if (type === 'par') {
      return array?.filter((_, index) => index % 2 === 0);
    } else {
      return array?.filter((_, index) => index % 2 === 1);
    }
  }

  function renderData(array: OnlineExchange[]) {
    const orderedArray = order(array);
    const firstPart = name(orderedArray, "par");
    const secondPart = name(orderedArray, "impar");
    if (firstPart && secondPart) {
      setFirstPart(firstPart);
      setSecondPart(secondPart);
    }
  }

  function order(array: OnlineExchange[]) {
    return array.sort((a, b) => {
      if (!a.order) return 1;
      if (!b.order) return -1;
      return Number(a.order) - Number(b.order);
    });
  }

  const hideInfo = () => {
    setShowMoreId("");
    setShowMoreAll(false);
  };

  //convert timestamp to date minute and second

  const convertTime = (timestamp: number) => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - timestamp) / 1000);

    let result = '';

    if (diffInSeconds >= 86400) { // 86400 seconds in a day
        return null;
    } else if (diffInSeconds >= 3600) { // 3600 seconds in an hour
        const diffInHours = Math.floor(diffInSeconds / 3600);
        result = `${diffInHours} horas`;
    } else if (diffInSeconds >= 60) { // 60 seconds in a minute
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        result = `${diffInMinutes} min`;
    } else if (diffInSeconds >= 60) { // 60 seconds limit
        return null;
    } else {
        result = `${diffInSeconds} seg`;
    }

    return result;
  }


  return (
    <div className={`${page === "cambioOnline" ? "overflow-hidden max-w-5xl" : "overflow-hidden max-w-5xl md:flex"}`}>
      <div className={`${page === "cambioOnline" ? "w-full " : "md:w-1/2"}`}>
        <div className={`${page === "cambioOnline" ? "justify-end" : "justify-between"} flex mb-3 items-center`}>
          {showMoreAll ? (
            <div className={`${page === "cambioOnline" ? "hidden" : ""}  flex`} onClick={() => hideInfo()}>
              <div>
                <h3 className="text-secondary text-[16px] leading-5 w-24 md:w-[132px]">Ocultar información</h3>
              </div>
              <button className="mx-[18px] md:mx-6" aria-label='buttonArrow'>
                <span className="icon icon-arrow w-4 h-4 md:w-[24px] md:h-[24px]" />
              </button>
            </div>
          ) : (
            <div className={`${page === "cambioOnline" ? "hidden" : ""} flex`} onClick={() => setShowMoreAll(true)}>
              <h3 className="text-[#0096A6] text-xs md:text-[16px] leading-5  hover:text-secondary whitespace-nowrap">
                Mostrar información
              </h3>
              <button className="mx-[18px] " aria-label='buttonArrow2'>
                <ArrowCustom fill='#0096A6' width={23} height={23}/>
              </button>
            </div>
          )}
          <div className="flex md:justify-end mr-3 md:mr-0">
            <p
              className={`${
                currency == "PEN"
                  ? "hidden"
                  : currency == ""
                  ? "text-xs w-[55px] md:text-[14px] text-black text-center my-auto mx-1 md:w-20 md:mx-0"
                  : "flex justify-center text-xs md:text-[14px] text-black w-40"
              } ${valueCal ? 'mr-3 md:mr-0' : ''} `}
            >
              Compra
            </p>
            <p
              className={`${
                currency == "USD"
                  ? "hidden"
                  : currency == ""
                  ? "text-xs w-[55px] md:text-[14px] text-black text-center my-auto mx-2 md:w-20 md:mx-0"
                  : "flex justify-center text-xs md:text-[14px] text-black w-40"
              } ${valueCal ? 'mr-3 md:mr-0' : ''} `}
            >
              Venta
            </p>
          </div> 
        </div>
        {updatedExchangeHouses.map(({ bank, id, img, schedule, rates, color, site, width, height, order, change_max, change_min, cupon, tasas_referenciales,timestamp, convertedTime }, index) => (
          <div key={index} className={`${page === "cambioOnline" ? "" : "md:hidden"} my-1`}>
            <div className={index % 2 > 0 || id === showMoreId
                ? "bg-gray1 flex align-middle py-0.5 justify-between"
                : "flex align-middle py-0.5 justify-between"
              }
            >
              {img && (
                <div className='block'>
                  <div className="w-[90px] md:w-36 h-auto flex align-middle justify-center ml-2">
                    <Image src={`${img}`} width={parseInt(width)} height={parseInt(height)} layout="fixed" alt="img" />
                  </div>
                </div>
              )}
              {showMoreAll || id === showMoreId ? (
                <div className={`${page === "cambioOnline" ? "hidden " : "my-auto"}`} onClick={() => hideInfo()}>
                  <button className="md:mx-4" aria-label={'boton_' + index}>
                    <span className="icon icon-arrow w-4 h-4"  />
                  </button>
                </div>
              ) : (
                <div className={`${page === "cambioOnline" ? "hidden " : "my-auto"}`} onClick={() => setShowMoreId(id)}>
                  <button className="mx-2 md:mx-4" aria-label={'boton2_' + index}>
                    <span className="icon icon-arrow rotate-180 w-4 h-4" />
                  </button>
                </div>
              )}
              <div className={`${page === "cambioOnline" ? "hidden" : "flex"} my-auto`}>
                <a href={site} target="_blank" rel="noreferrer nofollow">
                <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                </a>
              </div>
              <div className={`${page === "cambioOnline" ? "hidden w-1/2 md:flex" : "hidden"}`}>
                <div className="w-3/4 justify-start text-xs text-black my-auto mx-4">
                  <div className="mt-4">
                    <p>{bank}</p>
                      {change_min ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p> : ''}
                      {change_max ? <p className="text-xs text-black">Cambio máximo ${change_max} </p> : ""}
                      {cupon ? <p className="text-xs text-black">Cupón: {cupon} </p> : ""}
                      {tasas_referenciales ? <p className="text-xs text-black">Tasas preferenciales a partir de: {tasas_referenciales} </p> : ""}
                  </div>
                </div>
                <div className="w-1/4 flex md:justify-center text-xs md:text-black">
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end mr-4">L - V</p>
                      <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end ">S</p>
                      <p className="w-24 flex justify-start">{schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end">D</p>
                      <p className="w-24 flex justify-start">{schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${page === "cambioOnline" ? "flex my-auto" : "hidden"} '`}>
                <a href={site} target="_blank" rel="noreferrer nofollow">
                <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                </a>
              </div>
              <div className="flex items-center justify-between text-xl">
                <ValueQuotationV2
                  exchangeValue={rates}
                  title={"home"}
                  valueCal={valueCal}
                  currency={currency}
                  type={"home"}
                  section={section}
                  timestamp = {timestamp}
                  convertedTime= {convertedTime}
                />
              </div>
            </div>
            <div className={showMoreAll || id === showMoreId || page === "cambioOnline" ? "content show" : "content"}>
              <div className={`${page === "cambioOnline" ? "flex bg-Gray px-2 text-sm md:hidden" : "bg-Gray flex px-2 mb-2 text-sm"}`}>
                <div className="w-3/4 border-t-[1px] border-[#DADADA] flex justify-start text-xs text-black md:w-full">
                  <div className="w-4/5 mt-4">
                    <p>{bank}</p>
                      {change_min ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p> : ''}
                      {change_max ? <p className="text-xs text-black">Cambio máximo ${change_max} </p> : ""}
                      {cupon ? <p className="text-xs text-black">Cupón: {cupon} </p> : ""}
                      {tasas_referenciales ? <p className="text-xs text-black">Tasas preferenciales a partir de: {tasas_referenciales} </p> : ""}
                  </div>
                </div>
                <div className="w-1/4 border-t-[1px] border-[#DADADA] flex justify-center text-xs text-black md:w-full">
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                      <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end mb-1">S</p>
                      <p className="w-24 flex justify-start">{schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end">D</p>
                      <p className="w-24 flex justify-start">{schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {firstPart?.map(({ bank, id, img, schedule, rates, color, site, width, height, change_max, change_min, cupon, tasas_referenciales,timestamp, convertedTime }, index) => (
          <div key={index} className={`${page === "cambioOnline" ? "hidden" : "hidden md:block"} my-1`}>
            <div
              className={
                Number(index) % 2 > 0 || id === showMoreId
                  ? "bg-gray1 flex align-middle py-0.5 justify-between"
                  : `flex align-middle py-0.5 justify-between `
              }
            >
              {img && (
                <div className='block'>
                  <div className="exchange_img w-[80px] md:w-36 h-auto flex align-middle justify-center">
                    <Image src={`${img}`} width={parseInt(width)} height={parseInt(height)} layout="fixed" alt="img" />
                  </div>
                </div>
              )}
              {showMoreAll || id === showMoreId ? (
                <div className={`${page === "cambioOnline" ? "hidden " : "my-auto"}`} onClick={() => hideInfo()}>
                  <button className="mx-0 md:mx-1" aria-label={'boton3_' + index}>
                    <span className="icon icon-arrow" />
                  </button>
                </div>
              ) : (
                <div className={`${page === "cambioOnline" ? "hidden " : "my-auto"}`} onClick={() => setShowMoreId(id)}>
                  <button className="mx-0 md:mx-1" aria-label={'boton4_' + index}>
                    <span className="icon icon-arrow rotate-180" />
                  </button>
                </div>
              )}
              <div className={`${page === "cambioOnline" ? "hidden" : "flex"} my-auto`}>
                <a href={site} target="_blank" rel="noreferrer nofollow">
                <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                </a>
              </div>
              <div className={`${page === "cambioOnline" ? "hidden w-1/2 md:flex" : "hidden"}`}>
                <div className="w-3/4 justify-start text-xs text-black my-auto mx-4">
                  <div className="mt-4">
                    <p>{bank}</p>
                      {change_min ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p> : ''}
                      {change_max ? <p className="text-xs text-black">Cambio máximo ${change_max} </p> : ""}
                      {cupon ? <p className="text-xs text-black">Cupón: {cupon} </p> : ""}
                      {tasas_referenciales ? <p className="text-xs text-black">Tasas preferenciales a partir de: {tasas_referenciales} </p> : ""}
                  </div>
                </div>
                <div className="w-1/4 flex md:justify-end text-xs md:text-black">
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end mr-4">L - V</p>
                      <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end ">S</p>
                      <p className="w-24 flex justify-start">{schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end">D</p>
                      <p className="w-24 flex justify-start">{schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${page === "cambioOnline" ? "flex my-auto" : "hidden"} '`}>
                <a href={site} target="_blank" rel="noreferrer nofollow">
                <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                </a>
              </div>
              <div className="flex items-center justify-center text-xl">
                <ValueQuotationV2
                  exchangeValue={rates}
                  title={"home"}
                  valueCal={valueCal}
                  currency={currency}
                  type={"home"}
                  section={section}
                  timestamp = {timestamp}
                  convertedTime= {convertedTime}
                />
              </div>
            </div>
            <div className={showMoreAll || id === showMoreId || page === "cambioOnline" ? "content show" : "content"}>
              <div className={`${page === "cambioOnline" ? "flex bg-Gray px-2 text-sm md:hidden" : "bg-Gray flex px-2 mb-2 text-sm"}`}>
                <div className="w-3/4 border-t-[1px] border-[#DADADA] flex justify-start text-xs text-black md:w-full">
                  <div className="mt-4">
                    <p>{bank}</p>
                      {change_min ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p> : ''}
                      {change_max ? <p className="text-xs text-black">Cambio máximo ${change_max} </p> : ""}
                      {cupon ? <p className="text-xs text-black">Cupón: {cupon} </p> : ""}
                      {tasas_referenciales ? <p className="text-xs text-black">Tasas preferenciales a partir de: {tasas_referenciales} </p> : ""}
                  </div>
                </div>
                <div className="w-1/4 border-t-[1px] border-[#DADADA] flex justify-end text-xs text-black md:w-full">
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                      <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end mb-1">S</p>
                      <p className="w-24 flex justify-start">{schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end">D</p>
                      <p className="w-24 flex justify-start">{schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={`${page === "cambioOnline" ? "md:w-full" : "md:w-1/2"}`}>
        <div className={`${page === "cambioOnline" ? "hidden" : "hidden md:flex md:w-full md:mb-5 md:justify-end"}`}>
          <p
            className={`${
              currency == "PEN"
                ? "hidden"
                : currency == ""
                ? "text-xs md:text-[14px] text-black text-center my-auto w-20"
                : "flex justify-center text-xs md:text-[14px] text-black w-40"
            } `}
          >
            Compra
          </p>
          <p
            className={`${
              currency == "USD"
                ? "hidden"
                : currency == ""
                ? "text-xs md:text-[14px] text-black text-center my-auto w-20"
                : "flex justify-center text-xs md:text-[14px] text-black w-40"
            }`}
          >
            Venta
          </p>
        </div>
        {secondPart?.map(({ bank, id, img, schedule, rates, color, site, width, height, change_max, change_min, cupon, tasas_referenciales, timestamp, convertedTime }, index) => (
          <div key={index} className={`${page === "cambioOnline" ? "hidden" : "hidden md:block"} my-1`}>
            <div
              className={
                Number(index) % 2 > 0 || id === showMoreId
                  ? "bg-gray1 flex align-middle py-0.5 justify-between"
                  : `flex align-middle py-0.5 justify-between`
              }
            >
              {img && (
                <div className='block'>
                  <div className="exchange_img w-[90px] md:w-36 h-auto flex align-middle justify-center">
                    <Image src={`${img}`} width={parseInt(width)} height={parseInt(height)} layout="fixed" alt="img" />
                  </div>
                </div>
              )}
              {showMoreAll || id === showMoreId ? (
                <div className={`${page === "cambioOnline" ? "hidden " : "my-auto"}`} onClick={() => hideInfo()}>
                  <button className="mx-0 md:mx-1" aria-label={'boton_1_' + index}>
                    <span className="icon icon-arrow" />
                  </button>
                </div>
              ) : (
                <div className={`${page === "cambioOnline" ? "hidden " : "my-auto"}`} onClick={() => setShowMoreId(id)}>
                  <button className="mx-0 md:mx-1" aria-label={'boton_2_' + index}>
                    <span className="icon icon-arrow rotate-180" />
                  </button>
                </div>
              )}
              <div className={`${page === "cambioOnline" ? "hidden" : "flex"} my-auto`}>
                <a href={site} target="_blank" rel="noreferrer nofollow">
                <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                </a>
              </div>
              <div className={`${page === "cambioOnline" ? "hidden w-1/2 md:flex" : "hidden"}`}>
                <div className="w-3/4 justify-start text-xs text-black my-auto mx-4">
                  <div className="mt-4">
                    <p>{bank}</p>
                      {change_min ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p> : ''}
                      {change_max ? <p className="text-xs text-black">Cambio máximo ${change_max} </p> : ""}
                      {cupon ? <p className="text-xs text-black">Cupón: {cupon} </p> : ""}
                      {tasas_referenciales ? <p className="text-xs text-black">Tasas preferenciales a partir de: {tasas_referenciales} </p> : ""}
                  </div>
                </div>
                <div className="w-1/4 flex md:justify-end text-xs md:text-black">
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end mr-4">L - V</p>
                      <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end ">S</p>
                      <p className="w-24 flex justify-start">{schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end">D</p>
                      <p className="w-24 flex justify-start">{schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${page === "cambioOnline" ? "flex my-auto" : "hidden"} '`}>
                <a href={site} target="_blank" rel="noreferrer nofollow">
                <ButtonCed bg={color} text='Cambiar' textColor='#FFFFFF'/>
                </a>
              </div>
              <div className="flex items-center justify-center text-xl ">
                <ValueQuotationV2
                  exchangeValue={rates}
                  title={"home"}
                  valueCal={valueCal}
                  currency={currency}
                  type={"home"}
                  section={section}
                  timestamp = {timestamp}
                  convertedTime= {convertedTime}
                />
              </div>
            </div>
            <div className={showMoreAll || id === showMoreId || page === "cambioOnline" ? "content show" : "content"}>
              <div className={`${page === "cambioOnline" ? "flex bg-Gray px-2 text-sm md:hidden" : "bg-Gray flex px-2 mb-2 text-sm"}`}>
                <div className="w-3/4 border-t-[1px] border-[#DADADA] flex justify-start text-xs text-black md:w-full">
                  <div className="mt-4">
                    <p>{bank}</p>
                      {change_min ? <p className="text-xs text-black">Cambio mínimo ${change_min} </p> : ''}
                      {change_max ? <p className="text-xs text-black">Cambio máximo ${change_max} </p> : ""}
                      {cupon ? <p className="text-xs text-black">Cupón: {cupon} </p> : ""}
                      {tasas_referenciales ? <p className="text-xs text-black">Tasas preferenciales a partir de: {tasas_referenciales} </p> : ""}
                  </div>
                </div>
                <div className="w-1/4 border-t-[1px] border-[#DADADA] flex justify-end text-xs text-black md:w-full">
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end mr-4 mb-1">L - V</p>
                      <p className="w-24 flex justify-start">{schedule["M_F"]}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end mb-1">S</p>
                      <p className="w-24 flex justify-start">{schedule.Saturdays === "" ? "No hay atención" : schedule.Saturdays}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-9 flex justify-end">D</p>
                      <p className="w-24 flex justify-start">{schedule.Sundays === "" ? "No hay atención" : schedule.Sundays}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExchangeHousesV3;
