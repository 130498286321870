  import {InterbankExchangeRate } from "@framework/types/home";
import React, { FC, useState } from "react";
import { BankHours } from "./BankHours";
import IconInfo from "../icons/IconInfo";

export interface InterbankExchangeProps {
    exchangeBanks : InterbankExchangeRate | null,
    page : string
    currency: string,
    valueCal: number,
    section?: string,
    messageData?: any;
  }

const InterbankExchange : FC<InterbankExchangeProps> = ({ 
    exchangeBanks,
    page,
    valueCal,
    currency
}) => {

  const [showTime, setShowTime] = useState(false);
  const valueScotiank = exchangeBanks?.scotiabank;
  const valueBcp= exchangeBanks?.bcp;
  const valueInterbank = exchangeBanks?.Interbank;
  const valueBn = exchangeBanks?.nacion;
  const valueBbva = exchangeBanks?.bbva;

  const handleClick = () => {
    setShowTime(!showTime)
  }


  return (
    <div className="w-full ">
      <div className={`${page == 'cambioOnline' ? 'md:flex md:justify-end' : 'flex justify-between'} `}>
        <div className={`${page == 'cambioOnline' ? 'hidden': 'flex mr-2' }`}>
          <span className="text-[#0096A6] hover:text-[#0096A6] text-xs my-auto md:mr-4">
            Más información
          </span>
          <button className="mx-4" onClick={handleClick}>
            <IconInfo fill="#0096A6" width={16} height={16} />
          </button>
          {/* {showTime ? 
          <button className="mx-[18px] md:mx-[11px] flex align-middle" onClick={handleClick} aria-label="boton interbbancario 1">
                  <span className="icon icon-arrow my-auto" />  
          </button>
          :
          <button className="mx-[18px] md:mx-[11px]  flex align-middle" onClick={() => setShowTime(!showTime)} aria-label="boton interbbancario 1">
                  <span className="icon icon-arrow rotate-180 my-auto " />  
          </button>
          } */}
        </div>
        <div className={`${page == 'cambioOnline' ? 'hidden md:flex md:w-40 justify-center  ': 'flex w-40 md:w-80 justify-end' }`}>
          <div className={`${currency == 'PEN' ? 'hidden': currency == '' ? 'flex w-20 md:w-20 justify-center md:justify-center' : 'w-40 flex  justify-center'}`}>
            <p className={page == 'cambioOnline' ? 'text-xs mx-2 text-black text-center my-auto ' : 'text-xs text-black text-center my-auto mx-2 md:mx-0' }>
              Compra
            </p>
          </div>
          <div className={`${currency == 'USD' ? 'hidden': currency == '' ? 'flex w-20 md:w-20 justify-center md:justify-center' : 'w-40 ml-2 flex justify-center'} ${page == 'cambioOnline' ? '' : ''}`}>
            <p className="text-xs mx-2 text-black text-center my-auto">
              Venta
            </p>
          </div>
        </div>
      </div>
      {
        exchangeBanks && (
          <div>
            {/* <BankHours bank="caja" exchangeBanks={exchangeBanks} showTime = {showTime} page={page} valueCal={valueCal} currency={currency}/> */}
            <BankHours valueExchange={valueScotiank} bank="scotia" exchangeBanks={exchangeBanks} showTime = {showTime} page={page} valueCal={valueCal} currency={currency}/>
            <BankHours valueExchange={valueInterbank} bank="interbank" exchangeBanks={exchangeBanks} showTime = {showTime} page={page} valueCal={valueCal} currency={currency}/>
            <BankHours valueExchange={valueBbva} bank="bbva" exchangeBanks={exchangeBanks} showTime = {showTime} page={page} valueCal={valueCal} currency={currency}/>
            <BankHours valueExchange={valueBcp} bank="bcp" exchangeBanks={exchangeBanks} showTime = {showTime} page={page} valueCal={valueCal} currency={currency}/>
            <BankHours valueExchange={valueBn} bank="BN" exchangeBanks={exchangeBanks} showTime = {showTime} page={page} valueCal={valueCal} currency={currency}/>
          </div>
        )
      }
    </div>
  );
};

export default InterbankExchange;
