import React, { FC } from 'react';

type Props = {
    fill?: string;
    width?: number;
    height?: number;
};

const IconInfo : FC<Props> = ({fill, width, height, ...props}) => (

    <svg 
        width={width ? width : '16'}
        height={height ? height : '16'} 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="7.5" stroke={fill ? fill : '#0096A6'}/>
        <g clipPath="url(#clip0_7697_77097)">
        <path d="M8.88221 5.38804C8.84034 5.39214 8.79715 5.38804 8.75463 5.38804C8.34372 5.39009 7.96946 5.13881 7.83533 4.77455C7.66652 4.31306 7.86216 3.81803 8.30512 3.58797C8.80828 3.32778 9.4508 3.54346 9.66083 4.06589C9.93825 4.75469 9.43575 5.32915 8.88221 5.38735V5.38804Z" fill="#0096A6"/>
        <path d="M9.58804 11.148C9.5743 11.3513 9.46896 11.4547 9.28183 11.5129C8.98543 11.604 8.70866 11.7601 8.39721 11.8073C8.27879 11.8251 8.16036 11.821 8.08642 11.8258C7.72001 11.832 7.41511 11.7752 7.15143 11.5656C6.85895 11.3328 6.7523 11.0179 6.77128 10.642C6.80792 9.93058 7.06833 9.27533 7.23191 8.59611C7.29406 8.33798 7.36865 8.08258 7.40333 7.81761C7.50213 7.06102 7.19788 7.01377 6.68229 7.05211C6.57826 7.05965 6.47619 7.10415 6.37477 7.13496C6.25242 7.17194 6.25438 7.16714 6.26681 7.04253C6.2884 6.81726 6.39178 6.69402 6.60443 6.62623C6.94467 6.51668 7.27313 6.36057 7.63299 6.32839C8.00333 6.29484 8.36581 6.32839 8.68053 6.56461C8.91804 6.74332 9.04824 6.98912 9.06591 7.29792C9.08946 7.70737 9.00899 8.10039 8.90168 8.49203C8.74923 9.05143 8.58107 9.6074 8.46657 10.1778C8.43451 10.3393 8.41554 10.5023 8.42666 10.668C8.44367 10.922 8.55228 11.0446 8.78783 11.0946C9.02142 11.1432 9.24388 11.1007 9.46503 11.0282C9.50756 11.0145 9.55729 10.97 9.59524 11.009C9.63123 11.046 9.59131 11.1007 9.58804 11.148Z" fill={fill ? fill : '#0096A6'}/>
        </g>
        <defs>
        <clipPath id="clip0_7697_77097">
        <rect width="3.47826" height="8.34783" fill="white" transform="translate(6.26099 3.47852)"/>
        </clipPath>
        </defs>
    </svg>

)

export default IconInfo;