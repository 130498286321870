import React, { FC } from 'react'

type ButtonCedProps = {
    text: string,
    bg: string,
    textColor: string,
    border?: string,
    // Props here
    }

const ButtonCed: React.FC<ButtonCedProps> = ({
    bg,
    textColor,
    text,
    border
    // Props here
}) => { 
  return (
    <button 
    style={{backgroundColor: bg, color: textColor, borderColor: border}}
    className={`text-sm font-medium px-6 py-1.5 rounded-full`}>{ text }</button>
  )
}

export default ButtonCed
